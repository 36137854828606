import type { ReactNode } from 'react';
import { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useTheme } from '../../hooks/useTheme';

export function RootTheme({ children }: { children: ReactNode }) {
  const [theme] = useTheme();

  useLayoutEffect(() => {
    document.body.classList.toggle('ppl-theme-light', theme !== 'dark');
    document.body.classList.toggle('ppl-theme-dark', theme === 'dark');
  }, [theme]);

  return (
    <>
      <Helmet>
        <meta name="color-scheme" content={theme} />
      </Helmet>

      {children}
    </>
  );
}
