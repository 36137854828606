import { Avatar, Drawer, DrawerModal, IconButton, MenuIcon, Size, Text } from '@pledge-earth/product-language';
import { DialogTrigger } from 'react-aria-components';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

import { useAppSelector } from '../../store/hooks';
import { imagesCdnUrl } from '../../utils/cdn';
import { AvatarImage } from '../AvatarImage/AvatarImage';

import { NavigationMenu } from './NavigationMenu';

export function MobileMenu() {
  const { formatMessage } = useIntl();
  const location = useLocation();

  const { client, client_avatar: clientAvatar } = useAppSelector((state) => state.user);

  return (
    <DialogTrigger key={location.pathname}>
      <IconButton variant="subtle" label={formatMessage({ id: 'expand' })} className="mr-auto shrink-0 lg:hidden">
        <MenuIcon />
      </IconButton>

      <DrawerModal placement="left" size="small">
        <Drawer
          title={
            <div className="flex items-center gap-2">
              <Avatar className="shrink-0" variant="square">
                <AvatarImage src={imagesCdnUrl(clientAvatar?.key)} fallback={client?.charAt(0)?.toUpperCase()} />
              </Avatar>

              <Text size={Size.Loose}>{client}</Text>
            </div>
          }
        >
          <NavigationMenu className="p-4" />
        </Drawer>
      </DrawerModal>
    </DialogTrigger>
  );
}
