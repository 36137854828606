import type { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router';

import { useAppSelector } from '../../store/hooks';

export function RequireAuthenticated(props: { children: ReactNode }) {
  const user = useAppSelector((state) => state.user);
  const { pathname, search } = useLocation();
  const currentPath = pathname + search;

  const isUserAuthenticated = user.authenticated;

  // redirect to login page if current is not login page and user not authorized
  if (!isUserAuthenticated) {
    const searchParams = new URLSearchParams();

    if (
      pathname !== '/' &&
      pathname !== '/auth/sign-in' &&
      !pathname.startsWith('/error_description') // Google error
    ) {
      searchParams.set('redirectTo', currentPath);
    }

    return <Navigate to={{ pathname: '/auth/sign-in', search: searchParams.toString() }} replace={true} />;
  }

  // allow through
  return props.children;
}
