import type { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router';

import { useAppSelector } from '../../store/hooks';

export function RequireActiveSubscription(props: { children: ReactNode }) {
  const user = useAppSelector((state) => state.user);
  const { pathname } = useLocation();

  const isSubscriptionActive = user.subscription.is_active;

  // check if the user has an active subscription
  if (!isSubscriptionActive && pathname !== '/trial-expired') {
    return <Navigate to="/trial-expired" replace={true} />;
  }

  // allow through
  return props.children;
}
