import type { NavLinkProps as ReactRouterNavLinkProps } from 'react-router';
import { NavLink as ReactRouterNavLink } from 'react-router';

import { useIsTestMode } from '../../hooks/useIsTestMode';
import { configureToIfTestMode } from '../../hooks/useNavigateWithTestMode';

export function ReactRouterNavLinkWithTestMode({ to, ...props }: ReactRouterNavLinkProps) {
  const testModeEnabled = useIsTestMode();

  return <ReactRouterNavLink to={configureToIfTestMode(testModeEnabled, to)} {...props} />;
}
