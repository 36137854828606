import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { invoke } from 'lodash';
import {
  Separator,
  ActionMenu,
  MenuItem,
  MenuSeparator,
  Avatar,
  Badge,
  ChevronDownIcon,
  HelpCircleFilledIcon,
  IconButton,
  NotificationFilledIcon,
  Size,
  Text,
} from '@pledge-earth/product-language';

import { AvatarImage } from '../../AvatarImage/AvatarImage';
import { ClientRoleEnum } from '../../../services/graphql/generated';
import { logout as logoutAction } from '../../../store/user/reducers';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Entitlement, PLATFORM_SUPPORT_MESSENGER_ENTITLEMENT, hasEntitlement } from '../../Entitlement/Entitlement';
import { toLowercase } from '../../../utils/toLowercase';
import { CookieYesLink } from '../../CookieYesLink/CookieYesLink';
import { ACL } from '../../ACL/ACL';
import { imagesCdnUrl } from '../../../utils/cdn';

const hasSupportMessengerEntitlement = hasEntitlement(PLATFORM_SUPPORT_MESSENGER_ENTITLEMENT);

export function UserMenu() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const { formatMessage } = useIntl();
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    // Intercom notifications in the top bar
    invoke(window, 'Intercom', 'onUnreadCountChange', (unreadCount: number) => {
      setNotificationCount(unreadCount);
    });
  });

  const roleKey = useMemo(() => {
    // Default to viewer
    const role: ClientRoleEnum = (user.role || ClientRoleEnum.Viewer) as ClientRoleEnum;
    return `team.member.role.${toLowercase(role)}` as const;
  }, [user.role]);

  const displayName = useMemo(
    () => (user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : ''),
    [user.first_name, user.last_name],
  );

  return (
    <div className="flex flex-row items-center gap-4">
      <div className="flex flex-row gap-2">
        <Entitlement
          isEntitled={hasSupportMessengerEntitlement}
          fallback={
            <IconButton
              elementType="a"
              label={formatMessage({ id: 'topBar.help' })}
              variant="plain"
              href="https://help.pledge.io/en/"
              target="_blank"
            >
              <HelpCircleFilledIcon className="text-body-lg" />
            </IconButton>
          }
        >
          <IconButton
            label={formatMessage({ id: 'topBar.notifications' })}
            variant="plain"
            onPress={() => invoke(window, 'Intercom', 'showSpace', 'messages')}
            className="relative -top-px"
          >
            <NotificationFilledIcon />
            {notificationCount > 0 && (
              <Badge value={notificationCount} size={Size.Loose} className="absolute -top-1 left-3 outline outline-1" />
            )}
          </IconButton>
          <IconButton
            label={formatMessage({ id: 'topBar.help' })}
            variant="plain"
            onPress={() => invoke(window, 'Intercom', 'showSpace', 'home')}
          >
            <HelpCircleFilledIcon />
          </IconButton>
        </Entitlement>
      </div>
      <Separator variant="vertical" className="mx-2 h-8" />
      <div>
        <ActionMenu
          triggerElement={
            <IconButton label="User menu" variant="plain" className="w-auto">
              <Avatar>
                <AvatarImage
                  src={imagesCdnUrl(user.avatar?.key)}
                  fallback={user.first_name?.charAt(0)?.toUpperCase()}
                />
              </Avatar>
              <ChevronDownIcon />
            </IconButton>
          }
        >
          <MenuItem key="details" isDisabled={true} className="opacity-100!">
            <div className="flex flex-col">
              <Text slot="label" variant="subdued">
                {displayName}
              </Text>
              <Text slot="description" variant="subdued">
                <FormattedMessage id={roleKey} />
              </Text>
            </div>
          </MenuItem>

          <MenuSeparator />

          <MenuItem key="profile" href="/profile">
            <FormattedMessage id="topBar.profileMenu.editProfile" />
          </MenuItem>

          <MenuSeparator key="divider-team" />

          <ACL roles={[ClientRoleEnum.Admin, ClientRoleEnum.Owner]}>
            <MenuItem key="organization" href="/organization">
              <FormattedMessage id="topBar.profileMenu.organization" />
            </MenuItem>
            <MenuItem key="team" href="/team">
              <FormattedMessage id="topBar.profileMenu.team" />
            </MenuItem>
            <MenuItem key="billing" href="/billing">
              <FormattedMessage id="topBar.profileMenu.billing" />
            </MenuItem>
            <MenuItem key="invoices" href="/invoices">
              <FormattedMessage id="topBar.profileMenu.invoices" />
            </MenuItem>
          </ACL>
          <MenuItem key="plans" href="/plans">
            <FormattedMessage id="topBar.profileMenu.plans" />
          </MenuItem>

          <MenuSeparator />

          <MenuItem key="privacy_policy" href="https://www.pledge.io/privacy" target="_blank">
            <FormattedMessage id="topBar.profileMenu.privacy-policy" />
          </MenuItem>
          <MenuItem key="terms" href="https://www.pledge.io/terms" target="_blank">
            <FormattedMessage id="terms" />
          </MenuItem>
          <MenuItem key="cookie-settings">
            <CookieYesLink>
              {(props) => (
                <button type="button" className="contents" {...props}>
                  <FormattedMessage id="topBar.profileMenu.cookie-settings" />
                </button>
              )}
            </CookieYesLink>
          </MenuItem>

          <MenuSeparator key="divider-logout" />

          <MenuItem key="logout" onAction={() => dispatch(logoutAction())}>
            <FormattedMessage id="topBar.profileMenu.logout" />
          </MenuItem>
        </ActionMenu>
      </div>
    </div>
  );
}
