import { BannerMessage, Strong } from '@pledge-earth/product-language';
import { FormattedMessage } from 'react-intl';
import { useCallback } from 'react';

import {
  ClientRoleEnum,
  ClientUserTipStatusEnum,
  ClientUserTipTypeEnum,
  DismissClientUserTipDocument,
  GetClientUserUsageWarningTipDocument,
} from '../../services/graphql/generated';
import { useMutationWithTestMode } from '../../hooks/useMutationWithTestMode';
import { useQueryWithTestMode } from '../../hooks/useQueryWithTestMode';
import { Link } from '../Link/Link';
import { useAppSelector } from '../../store/hooks';

export function UsageNotice() {
  const { role } = useAppSelector((state) => state.user);

  const { data: shipmentLegsUsageWarning, loading } = useQueryWithTestMode(GetClientUserUsageWarningTipDocument, {
    fetchPolicy: 'cache-first',
    skip: role !== ClientRoleEnum.Owner && role !== ClientRoleEnum.Admin,
  });

  const [shipmentLegsUsageWarningDismissMutation] = useMutationWithTestMode(DismissClientUserTipDocument, {
    refetchQueries: [GetClientUserUsageWarningTipDocument],
    awaitRefetchQueries: true,
  });
  const handleDismiss = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    shipmentLegsUsageWarningDismissMutation({
      variables: {
        type:
          shipmentLegsUsageWarning?.usage_warning_tip?.type ??
          ClientUserTipTypeEnum.FreeVolumePricedShipmentLegsUsageWarning,
        id: shipmentLegsUsageWarning?.usage_warning_tip?.id ?? '',
      },

      optimisticResponse: {
        dismiss_client_user_tip: {
          __typename: 'ClientUserTip',
          status: ClientUserTipStatusEnum.Dismissed,
          type:
            shipmentLegsUsageWarning?.usage_warning_tip?.type ??
            ClientUserTipTypeEnum.FreeVolumePricedShipmentLegsUsageWarning,
          id:
            shipmentLegsUsageWarning?.usage_warning_tip?.id ??
            ClientUserTipTypeEnum.FreeVolumePricedShipmentLegsUsageWarning,
        },
      },
    });
  }, [shipmentLegsUsageWarning?.usage_warning_tip, shipmentLegsUsageWarningDismissMutation]);

  if (
    loading ||
    !shipmentLegsUsageWarning?.usage_warning_tip ||
    shipmentLegsUsageWarning.usage_warning_tip.status !== ClientUserTipStatusEnum.Active
  ) {
    return null;
  }

  return (
    <BannerMessage variant="billing" rounded={false} isDismissible={true} onDismiss={handleDismiss}>
      <FormattedMessage
        id={
          shipmentLegsUsageWarning.usage_warning_tip.type ===
          ClientUserTipTypeEnum.FreeVolumePricedShipmentLegsUsageExceeded
            ? 'show_usage_notice_exceeded'
            : 'show_usage_notice'
        }
        values={{
          // eslint-disable-next-line react/no-unstable-nested-components
          a: (text) => (
            <Link
              className="text-default hover:text-default-hover"
              to={{
                pathname: '/billing',
              }}
            >
              <Strong>{text}</Strong>
            </Link>
          ),
        }}
      />
    </BannerMessage>
  );
}
