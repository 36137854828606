import type { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router';

import { useAppSelector } from '../../store/hooks';
import {
  selectUserAuthenticated,
  selectUserRequiresCreateProfile,
  selectUserRequiresCreateOrganization,
  selectUserRequiresInvite,
} from '../../store/user/selectors';

export function RequireCompletedSignUp(props: { children: ReactNode }) {
  const location = useLocation();
  const isUserAuthenticated = useAppSelector(selectUserAuthenticated);
  const userRequiresCreateProfile = useAppSelector(selectUserRequiresCreateProfile);
  const userRequiresCreateOrganization = useAppSelector(selectUserRequiresCreateOrganization);
  const userRequiresInvite = useAppSelector(selectUserRequiresInvite);

  // check if the user has been fully created
  if (isUserAuthenticated) {
    if (userRequiresCreateProfile) {
      if (location.pathname !== '/auth/create-profile') {
        return <Navigate to={{ ...location, pathname: '/auth/create-profile' }} replace={true} />;
      }
    } else if (userRequiresCreateOrganization) {
      if (location.pathname !== '/auth/create-organization') {
        return <Navigate to={{ ...location, pathname: '/auth/create-organization' }} replace={true} />;
      }
    } else if (userRequiresInvite) {
      if (location.pathname !== '/auth/request-send') {
        return <Navigate to={{ ...location, pathname: '/auth/request-send' }} replace={true} />;
      }
    }
  }

  // if they are not authenticated or the user is fully created, allow through
  return props.children;
}
