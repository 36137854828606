import { Button, Text } from '@pledge-earth/product-language';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';

import { ClientSubscriptionStatusEnum } from '../../services/graphql/generated';
import { useAppSelector } from '../../store/hooks';

export function TrialCountDown() {
  const { status, trial_end_date: trialEndDate } = useAppSelector((state) => state.user.subscription);
  const { client_chargebee_customer_card_status: cardStatus } = useAppSelector((state) => state.user);
  const isValidCard = cardStatus === 'valid';

  // Check subscription status, the trial end date, and card status
  if (status !== ClientSubscriptionStatusEnum.InTrial || trialEndDate === null || isValidCard) {
    return null;
  }

  const diffTime = new Date(trialEndDate).getTime() - new Date().getTime();
  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

  return (
    <div
      className="hidden flex-row items-center gap-2 rounded-sm bg-accent-cornflower-subdued px-2 py-1 sm:flex"
      data-cy="trial-count-down"
    >
      <Text className="text-accent-cornflower-bold" data-cy="trial-count-down-days-left">
        {diffDays === 0 ? (
          <FormattedMessage id="trialCountDown.last_day" />
        ) : (
          <FormattedMessage id="trialCountDown.days_left" values={{ diffDays }} />
        )}
      </Text>
      <Button variant="primary-alt" elementType={Link} to="/plans">
        <FormattedMessage id="trialCountDown.select_plan" />
      </Button>
    </div>
  );
}
