import { Outlet } from 'react-router';
import { Suspense } from 'react';

import { LoadingFullScreen } from '../../components/Loading/LoadingFullScreen';

export function DiscoverLayout() {
  return (
    <Suspense fallback={<LoadingFullScreen />}>
      <Outlet />
    </Suspense>
  );
}
